export default {
    name: "loading-screen",
    data: function() {
        return {
            text: null,
        }
    },
    props: {
        title: {
            type: String,
            default: "Please wait..."
        },
        loadingText: {
            type: Array,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        startLoading() {
        let i = 0
        let textInterval = setInterval(()=>{
            if (i< this.loadingText.length) {
                this.text = this.loadingText[i]
                i+=1
            }
            else{
                i=0
                this.text = this.loadingText[0]
            }
            if (!this.loading) {
                clearInterval(textInterval)
            }
        },500)
        }
    },
    watch: {
        loading() {
            if(this.loading) {
                this.startLoading();
            }
        }
    },
    mounted() {
        this.startLoading()
    }
}