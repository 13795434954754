<template>
    <aside>
        <div class="text-white  px-2 py-2 rounded-t-lg entity">
            Entity Menu
        </div>
        <div class="sidebar cursor-pointer border-2 text-base-content">
            <ul>
                <li v-for="item in navItems" :key="item.id" class="border-b-2 h-10 hover:bg-red-100"
                    @click="selectPage(item)" :class="{ 'activeCard': isActive(item) }">
                    <div class="flex items-center">
                        <span
                            class="client-card-info self-stretch w-56  text-sm text-gray-600 font-small  capitalize px-2 py-2">
                            {{ item.name }}
                        </span>
                        <span v-if="item.children"
                            class="icon text-base ml-auto mr-2 w-4  transition-transform transform ease-out duration-300"
                            :class="expand ? ' rotate-180' : 'rotate-0'">
                            <font-awesome-icon icon="chevron-down" />
                        </span>
                        <component v-if="item.checkAlert" :is="icons.activity['bounceEmail']" class="mr-2 " />
                    </div>
                    <ul v-if="item.children && expand" class="mt-1 p-0 border">
                        <li v-for="child in item.children" :key="child.subname"
                            class="sub-item items-center flex border-b-2 h-9 hover:bg-red-100 "
                            :class="{ 'activeCard': isActiveChild(child) }" @click="selectPage(child)">
                            <span class="pl-4 self-stretch w-56  text-sm text-gray-600 font-small capitalize px-2 py-2">
                                {{ child.subname }}
                            </span>
                            <span class="mr-2">
                                <font-awesome-icon :icon="['fas', 'plus']" color="red" size="xs" />
                            </span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </aside>
</template>
<script>
const bounceBackEmailIcon = () => import("@/assets/icons/dashboard/activity/bounce-back-email.svg");
export default {
    props: ["navItems", "selectedPage"],
    data() {
        return {
            expand: false,
            checkAlert: true,
            icons: {
                activity: {
                    bounceEmail: bounceBackEmailIcon
                },
            },
        }
    },
    mounted() {
        console.log(this.selectedPage)
    },
    methods: {
        childExpand() {
            this.expand = !this.expand;
        },
        selectPage(page) {
            if (page.children) {
                this.childExpand();
                this.$emit('sidebarItemClicked', page.sub_id);
            }
            else {
                this.$emit('sidebarItemClicked', page.id);
            }
        },
        isActive(item) {
            return this.selectedPage === item.id;
        },
        isActiveChild(child) {
            return this.selectedPage === child.subname;
        }
    }
};
</script>
  
<style scoped lang="scss">
.sidebar {}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    cursor: pointer;
}

.activeCard {
    background-color: #FFEBEB;
}

.entity {
    border-bottom: 1px solid var(--gray, #E9E9EA);
    background: var(--light-ray-main-red, #910000);
}

.sub-item {}
</style>



