<template>
  <div>
    <div>
    </div>
    <div class="navbar_parent">
      <ul  class="text-sm font-medium text-center text-gray-500 divide-x divide-gray-200  sm:flex h-9">
        <li v-for="(item, index) in mainCasedata"  :key="index" class="w-full  shadow" >
          <div  class="flex justify-between items-center px-4 py-1 text-gray-900 bg-white hover:bg-red-10 w-42"
            :class="item.checked ? 'bg-red-100' : ''">
            <span class="w-7 h-7  rounded-full bg-red-200 flex justify-center items-center"
              v-if="item?.categories_type === 'officers'"> 
              <UserIcon />
            </span>
            <span class="w-7 h-7  rounded-full bg-red-200 flex justify-center items-center" v-else>
              <CompanyIcon />
            </span>
            <label :title="item?.entity" :for="`userid_${index}`"> {{ item?.entity.length > 9 ? `${item.entity.substr(0, 9)}...` : item?.entity }}</label>
            <span class="text-left">
              <CanvasMenu  :individualData="item" :entityHead="true" :mainEntity="caseData" :isMainenity="true"/>
            </span>
            <input type="checkbox"  v-model="item.checked" @change="handleCheckboxChange(item,index)" :id="`userid_${index}`" />
          </div>
        </li>
        <li class="w-full dropdown_parent">
          <button @click="toggleList" v-show="multiData.length > 0" ref="dropdown"
            class="w-full text-red-800 bg-white  flex justify-around items-center h-9">
            <div class="flex relative ">
              <span class="z-0  w-7 h-7 border-2 border-white rounded-full bg-red-200 flex justify-center items-center">
                <UserIcon />
              </span>
              <span
                class="absolute border-2 border-white left-5 z-10 w-7 h-7 p-1 rounded-full bg-red-200 flex justify-center items-center">
                <CompanyIcon />
              </span>
            </div>
            + &nbsp;{{ multiData.length }} More
            <font-awesome-icon :icon="['fas', 'chevron-down']" color="black" size="xs" />
          </button>
          <ul v-if="showList"
            class="dropdown_content  text-sm font-medium text-center text-gray-500 divide-y divide-gray-200  border border-gray-200 shadow  h-auto">
            <li v-for="(item, index) in multiData" :key="index" class="w-42 h-9">
              <div class="flex justify-between items-center w-full px-4 py-1 text-gray-900 bg-white hover:bg-red-100 position-relative"
                :class="item.checked ? 'bg-red-100' : ''">
                <span class="w-7 h-7 rounded-full bg-red-200 flex justify-center items-center"
                  v-if="item.categories_type === 'officers'">
                  <UserIcon />
                </span>
                <span class="w-7 h-7  rounded-full bg-red-200 flex justify-center items-center" v-else>
                  <CompanyIcon/>
                </span>
                <span :title="item.name" :for="`userid_${index}`"> {{ item.name.length > 8 ? `${item.name.substr(0, 8)}...` : item.name }}</span>
                <span class="text-left " > <CanvasMenu :individualData="item" :entityHead="true"/></span> 
                <input type="checkbox" id="dropdown" v-model="item.checked" @change="handleCheckboxChange(item,index)" />
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

import UserIcon from "../../assets/svg/user.svg";
import CompanyIcon from "../../assets/svg/company.svg";
import CanvasMenu from "../../components/canvas-menu"

export default {
  components: {
    UserIcon,
    CompanyIcon,
    CanvasMenu,
  },
  props: ['caseData'],
  data() {
    return {
      showList: false,
      isCanvas: false,
    }
  },
  methods: {
    handleCheckboxChange(item,index) {
      console.log("item",item)
      this.$emit('caseData-checked', item,index);
    },
    toggleList() {
      this.showList = !this.showList;
    },
    handleOutsideClick(event) {
      if(this.isCanvas==true){ return }
      if (!this.$refs.dropdown.contains(event.target) && (!(event.target.id == 'dropdown') )){
        if(event.target.id == 'isCanvas'){this.isCanvas=true}
        this.showList = false;
      }
    },
  },
  watch: {
   
  },
  mounted() {
    
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  computed: {
    mainCasedata() {
      if (this.caseData.length <= 5) {
        return this.caseData;
      }
      else {
        return this.caseData.filter((ele, index) => (index < 4))
      }
    },
    multiData() {
      return this.caseData.filter((ele, index) => (index >= 4))
    },
  }
};
</script>
<style lang="scss" scoped>
.navbar_parent {
  li {
    cursor: pointer !important;
  }
}

.dropdown_parent {
  position: relative !important;
}

.dropdown_content {
  position: absolute !important;
  top: 100% !important;
  left: 0%;
  z-index: 1000 !important;
  width: inherit;
}
</style>