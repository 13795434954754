<template>
  <div class="border rounded-lg m-2 bg-white p-4 flex flex-row  leading-normal">
    <div v-if="getImage" class="mr-2">
      <div class="w-28 bg-slate-700">
        <img :src="getImageLink" class="w-28 h-auto" />
      </div>
    </div>
    <div class="flex flex-col w-full">
      <div class="text-primary  text-md mb-2 flex justify-between">
        <a :href="getlink" target="_blank">{{ getlink }}</a>
        <div class="flex">
          <div class="mx-1 label-box " :class="confidenceColor">
          <span>{{ this.item?.confidence_score?.score || '0' }}</span>
        </div>
          <div class="mx-1 label-box  dropdown_parent" :class="this.status">
            <span class="label" @click="this.toogleDropdown">
              <MinusIcon v-if="this.status == 'unknown'" class="cursor-pointer" />
              <Check v-else-if="this.status == 'confirmed'" class="cursor-pointer" />
              <Question v-else-if="this.status == 'potential'" class="cursor-pointer" />
              <Cross v-else-if="this.status == 'irrelvant'" class="cursor-pointer" />
            </span>
            <ul v-if="showList" class="dropdown_content w-36 text-sm font-small bg-white shadow h-auto py-2 card">
              <li @click="selectDropdown" name="confirmed"
                class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
                <span name="confirmed" class="" style="color: #67B52B">Confirmed</span>
                <span name="confirmed" class="badge " style="background:#F0F8EA ">
                  <Check name="confirmed" />
                </span>
              </li>
              <li @click="selectDropdown" name="potential"
                class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
                <span name="potential" class="" style="color: #FC6713">Potential</span>
                <span name="potential" class="badge " style="background:rgba(252, 103, 19, 0.11) ">
                  <Question name="potential" />
                </span>
              </li>
              <li @click="selectDropdown" name="irrelvant"
                class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
                <span name="irrelvant" class="" style="color: #EB3131">Irrelvant</span>
                <span name="irrelvant" class="badge " style="background:#FBEDED">
                  <Cross name="irrelvant" />
                </span>
              </li>
              <li @click="selectDropdown" name="unknown" class="p-2 flex items-center justify-between">
                <span name="unknown" class="" style='color:#8D8D8D'>unknown</span>
                <span name="unknown" class="badge" style="background:#D7D7D7">
                  <MinusIcon name="unknown" />
                </span>
              </li>
            </ul>
          </div>
          <div class="mx-1">
            <Exclamation  class="cursor-pointer" :fill="riskcolor" />
          </div>
          <div class="mx-1">
            <CanvasMenu class="cursor-pointer" :isMainenity="false" :individualData="getIndividualData" :mainEntity="mainEntity" :sourceName="this.sourceName"/>
          </div>
        </div>
      </div>
      <p class="text-black text-sm">{{ getdescription }}</p>
    </div>
       
  </div>
</template>
<script>

import Exclamation from "@shared/assets/exclamation-red.svg";
import MinusIcon from "@shared/assets/minus-icon.svg";
import CanvasMenu from "../../components/canvas-menu";
import Check from '../../assets/svg/check.svg';
import Question from '../../assets/svg/question.svg';
import Cross from '../../assets/svg/cross-red.svg';
import { updateEntityDetails } from "../../services";

export default {
  components: {
    Exclamation,
    MinusIcon, 
    CanvasMenu,
    Check,
    Question,
    Cross
  },
  data(){
   return {
    showList: false,
    status: 'unknown',
   }
  },
  props: {
    mainEntity:Array,
    item: Object,
    name: String,
    sourceName:String,
    title: {
      type: String,
      default: "Put Some Title",
    },
    props: {
      title: {
        type: String,
        default: "Put Some Title",
      },
      whithImage: Boolean,
      message: {
        type: String,
        default:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum consequuntur autem nihil impedit consequatur et eius reiciendis asperiores explicabo eos magni totam, quisquam, ut alias cupiditate iste veniam facilis ratione.",
      },
      item: {
        type: Object
      }
    },
  },
  computed: {
    confidenceColor() {
      return (this.item?.confidence_score?.name).toLowerCase();
    },
    getImage() {
      return this.name == 'internetsearch' || this.name == 'news' ? true : false;
    },
    getImageLink() {
      return this.name == 'internetsearch' ? this.item?.thumbnail : this.item.image;
    },
    getIndividualData() {
      return this.item;
    },
    getlink() {
      return this.name == 'court' || this.name == 'news' ? this.item.url : this.item.link;
    },
    getdescription() {
      let courtString = ''
      if (this.name == 'court') {
        this.item.snippet.forEach(i => courtString += `${i}, `)
      }
      return this.name == 'news' ? this.item.description : this.name == 'court' ? courtString : this.item?.snippet;
    }
  },
  methods:{
    riskcolor(){
     let c = this.item.risk_categories.length>0 ? (this.item.entity_status === 'Red' ? `#EB3131` : `rgb(235, 155, 0)`) : `#42B85C`;
     return c  
    },
    toogleDropdown() {
      this.showList = !this.showList;
    },
    selectDropdown(event) {
      this.showList = false;
      let  key  = event.target.innerText
      this.status = key.toLowerCase();
      this.item.status = this.status.toUpperCase();
      let payload = {...this.item,  case_id :this.$route.query.case_id}
      let data= updateEntityDetails(payload,this.item._id);
      if(data){
        this.$toast.success("Status updated")
      }
    }
  }
};
</script>
<style class="scss" scoped>
.label-box {
  display: flex;
  width: 25px;
  height: 24px;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}

.label-box-bg {
  border-radius: 4px;
  background: var(--light-green, #E1F3D3);
}

.minus-box-bg {
  border-radius: 30px;
  background: #D7D7D7;
}

.label {
  color: var(--complete, #67B52B);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.unknown{
  border-radius: 30px;
  background: #D7D7D7;
}
.confirmed{
  border-radius: 30px;
  background:#F0F8EA;
}
.potential{
  border-radius: 30px;
  background:rgba(252, 103, 19, 0.11)
}
.irrelvant{
  border-radius: 30px;
  background:#FBEDED; 
}
.high {
  font-size: 12px;
  font-weight: 400;
  color: #67B52B !important;
  background-color: #E1F3D3;
}

.low {
  font-size: 12px;
  font-weight: 400;
  color: #b52b2b !important;
  background-color: #f3d3d3;
}

.medium {
  font-size: 12px;
  font-weight: 400;
  color: #a7b52b !important;
  background-color: #f3f0d3;
}

.dropdown_parent {
  position: relative !important;
}

.badge {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: white;
  border: 0px solid transparent !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important
}

.dropdown_content {
  position: absolute !important;
  top: 120% !important;
  left: 0%;
  z-index: 99999 !important;
  border-radius: 8px;
  background: var(--white, #FFF);
  box-shadow: 2px 2px 11px 0px rgba(68, 82, 90, 0.09);

  li:hover {
    background-color: #D7D7D7;
  }
}
</style>