export default {
    name: "Attributes",
    components: {

    },
    props: {
        individualData : Object,
        data:Object
    },
    data() {
        return {
            type: "user",
            formData: {
                companyNumber:'',
                incorporation_date:'',
                dissolution_date:'',
                country:'',
                state:'',
                firstName: '',
                middleName: '',
                lastName: '',
                dob: '',
                gender: '',
                mainEntity: false,
                showGraph: true,
                graphLabel: '',
            },
        
        };
    },
    methods: {

    },
    mounted() {

    },
    computed() {
    },
};
