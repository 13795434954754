<template>
    <div class="scrollbar w-full  relative scroll-bar min-h-full flex flex-col">
        <div class="w-full overflow-y-auto scroll-bar ">
            <AccordionCard>
                <template v-slot:title>
                  <span class="font-semibold text-xl">Company Details</span>
                </template>
                <template v-slot:content>
                <div v-for="item in returnData" class="grid gap-2 grid-cols-2 mx-2 my-2" :key="item.id">
                    <div>{{ item.CompanyName }}</div>
                    <div>{{ item.CompanyDetail }}</div>
                </div>
            </template>
            </AccordionCard>  
            <AccordionCard>
                <template v-slot:title>
                  <span class="font-semibold text-xl">Accounts</span>
                </template>
                <template v-slot:content>
                <!-- <div  class="grid gap-2 grid-cols-2 mx-2 my-2" > -->
                    <template >
                        <div class="flex flex-col" v-for="item in returnData" :key="item.id">
                            <div class="overflow-x-auto">
                                <div class="p-1.5 w-full inline-block align-middle">
                                    <div class="overflow-hidden border rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        class="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                                                    >
                                                        Assests
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                                                    >
                                                       Capital Employed
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                                                    >
                                                       Gross Profit
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase"
                                                    >
                                                       Net Worth
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase"
                                                    >
                                                        Total Assests
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200">
                                                <tr>
                                                    <td
                                                        class="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap"
                                                    >
                                                        101443
                                                    </td>
                                                    <td
                                                        class="px-6 py-4 text-sm text-gray-800 whitespace-nowrap"
                                                    >
                                                        101443
                                                    </td>
                                                    <td
                                                        class="px-6 py-4 text-sm text-gray-800 whitespace-nowrap"
                                                    >
                                                        0
                                                    </td>
                                                    <td
                                                        class="px-6 py-4 text-sm font-medium text-right whitespace-nowrap"
                                                    >
                                                        977864
                                                    </td>
                                                    <td
                                                        class="px-6 py-4 text-sm font-medium text-right whitespace-nowrap"
                                                    >
                                                          1312876
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap"
                                                    >
                                                        101443
                                                    </td>
                                                    <td
                                                        class="px-6 py-4 text-sm text-gray-800 whitespace-nowrap"
                                                    >
                                                        101443
                                                    </td>
                                                    <td
                                                        class="px-6 py-4 text-sm text-gray-800 whitespace-nowrap"
                                                    >
                                                        0
                                                    </td>
                                                    <td
                                                        class="px-6 py-4 text-sm font-medium text-right whitespace-nowrap"
                                                    >
                                                        977864
                                                    </td>
                                                    <td
                                                        class="px-6 py-4 text-sm font-medium text-right whitespace-nowrap"
                                                    >
                                                          1312876
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                <!-- </div> -->
            </template>
            </AccordionCard> 
        </div>
	</div>
</template>
<script>
import AccordionCard from "../../common/accordion";
export default{
    data(){
        return{
            Items:[
                {
                    id:1,
                    CompanyName:"Company Name",
                    CompanyDetail:"Neotas PRivate Ltd."
                }, 
                {
                    id:2,
                    CompanyName:"Tech superiors",
                    CompanyDetail:"Neotas PRivate Ltd."
                },
                ]
        }
    },
    components:{
        AccordionCard
    },
    computed:{
        returnData(){
            return this.Items
        }
    }
}
</script>
<style>
</style>