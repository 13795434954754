<template>
    <div class="scrollbar w-full  relative scroll-bar min-h-full flex flex-col filters-container">
        <div class="w-full overflow-y-auto scroll-bar ">
            <AccordionCard :isFilter="true">
                <template v-slot:title>
                    <span class="f-title flex items-center filter-color ">
                        <PeopleSVG class="mx-2" /> People
                    </span>
                   
                </template>
                <template v-slot:titleCheckbox>
                    <div class="ml-4">
                        <input type="checkbox" id="checkboxInput"
                        @change="$emit('handleAllCheckboxChange',$event,'names')" :checked="isAllPeopleChecked" />
                        <label  class="mx-1">Select all</label>
                    </div>
                </template>
                <template v-slot:content>
                    <div class="p-2">

                        <div class="form-control">
                            <Input class="w-70" v-model="filterData.peopleTerm"
                                @input="$emit('handleSearchboxChange', $event, 'peopleTerm')" icon="search"
                                placeholder="Search people" />
                        </div>
                        <div class="grid grid-cols-2 gap-4 py-2 pl-2">
                            <div c v-for="(person, cIdx) in getPeople" :key="person.name">
                                <input type="checkbox" @change="$emit('handleCheckboxChange', $event, cIdx, 'names')"
                                    :id="person.key" v-model="person.checked" class="" />
                                <label  class="filter-item-label mx-2">{{ person.key }}</label>
                            </div>
                        </div>
                        <span v-if="!filterData.seeMorePeople && filterData.names.length > 5"
                            @click="$emit('handleSeeMore', true, 'seeMorePeople')" class="f-title cursor-pointer pl-2">Load
                            More</span>
                        <span v-if="filterData.seeMorePeople" @click="$emit('handleSeeMore', false, 'seeMorePeople')"
                            class="f-title cursor-pointer pl-2">Show Less</span>

                    </div>

                </template>
            </AccordionCard>
            <!-- Company -->
            <AccordionCard :isFilter="true">
                <template v-slot:title>
                    <span class="f-title flex items-center filter-color ">
                        <CompanySVG class="mx-2" /> Company
                    </span>
                    <div class="ml-4">
                        <input type="checkbox" id="checkboxInput" :checked="isAllCompanyChecked"
                        @change="$emit('handleAllCheckboxChange',$event,'organisations')"  />
                        <label  class="mx-1">Select all</label>
                    </div>
                </template>
                <template v-slot:content>
                    <div class="p-2">

                        <div class="form-control">
                            <Input class="w-70" icon="search" v-model="filterData.companyTerm" id="companyTerm"
                                @input="$emit('handleSearchboxChange', $event, 'companyTerm')"
                                placeholder="Search company" />
                        </div>
                        <div class="grid grid-cols-2 gap-4 py-2 pl-2">
                            <div c v-for="(company, cIdx) in getCompanies" :key="company.key">
                                <input type="checkbox"
                                    @change="$emit('handleCheckboxChange', $event, cIdx, 'organisations')" :id="company.key"
                                    v-model="company.checked" class="" />
                                <label  class="filter-item-label mx-2">{{ company.key }} ({{ company.count
                                }})</label>
                            </div>
                        </div>
                        <span v-if="!filterData.seeMoreCompanies && filterData.organisations.length > 5"
                            @click="$emit('handleSeeMore', true, 'seeMoreCompanies')" class="f-title cursor-pointer pl-2">Load
                            More</span>
                        <span v-if="filterData.seeMoreCompanies" @click="$emit('handleSeeMore', false, 'seeMoreCompanies')"
                            class="f-title cursor-pointer pl-2">Show Less</span>

                    </div>
                </template>
            </AccordionCard>
            <!-- Status -->
            <AccordionCard :isFilter="true">
                <template v-slot:title>
                    <span class="f-title flex items-center filter-color ">
                        <StatusSVG class="mx-2" /> Status
                    </span>
                    <div class="ml-4">
                        <input type="checkbox" id="checkboxInput"
                        @change="$emit('handleAllCheckboxChange',$event,'status')" :checked="isAllStatusChecked" />
                        <label  class="mx-1">Select all</label>
                    </div>
                </template>
                <template v-slot:content>
                    <div class="p-2">

                        <div class="form-control">
                            <Input class="w-70" icon="search" v-model="filterData.statusTerm"
                            @input="$emit('handleSearchboxChange', $event, 'statusTerm')"  placeholder="Search status" />
                        </div>
                        <div class="grid grid-cols-2 gap-4 py-2 pl-2">
                            <div c v-for="(status,cIdx) in getStatus" :key="status.key">
                                <input type="checkbox"
                                @change="$emit('handleCheckboxChange', $event, cIdx, 'status')" :id="status.key"
                                 v-model="status.checked" class="" />
                                <label  class="filter-item-label mx-2">{{ status.key }} ({{ status.count
                                }})</label>
                            </div>
                        </div>

                    </div>
                </template>
            </AccordionCard>
            <!-- Risks -->
            <AccordionCard :isFilter="true">
                <template v-slot:title>
                    <span class="f-title flex items-center filter-color ">
                        <RiskSVG class="mx-2" /> Risks
                    </span>
                    <div class="ml-4">
                        <input type="checkbox" id="checkboxInput"
                        @change="$emit('handleAllCheckboxChange',$event,'risks')" :checked="isAllRisksChecked" />
                        <label  class="mx-1">Select all</label>
                    </div>
                </template>
                <template v-slot:content>
                    <div class="p-2">
                        <div class="form-control">
                            <Input class="w-70" icon="search" id="riskTerm"
                                @input="$emit('handleSearchboxChange', $event, 'riskTerm')" v-model="filterData.riskTerm"
                                placeholder="Search risk" />
                        </div>
                        <div class="grid grid-cols-2 gap-4 py-2 pl-2">
                            <div c v-for="(risk, rIdx) in getRisks" :key="risk.key">
                                <input type="checkbox" @change="$emit('handleCheckboxChange', $event, rIdx, 'risks')"
                                    :id="risk.key" v-model="risk.checked" class="" />
                                <label  class="filter-item-label mx-2">{{ risk.key }} ({{ risk.count
                                }})</label>
                            </div>
                        </div>
                        <span v-if="!filterData.seeMoreRisks && filterData.risks.length > 5"
                            @click="$emit('handleSeeMore', true, 'seeMoreRisks')" class="f-title cursor-pointer pl-2">Load
                            More</span>
                        <span v-if="filterData.seeMoreRisks" @click="$emit('handleSeeMore', false, 'seeMoreRisks')"
                            class="f-title cursor-pointer pl-2">Show Less</span>
                    </div>
                </template>
            </AccordionCard>
            <!-- Locations -->
            <!-- <AccordionCard :isFilter="true">
                <template v-slot:title>
                    <span class="f-title flex items-center filter-color ">
                        <LocationSVG class="mx-2" /> Locations
                    </span>
                </template>
                <template v-slot:content>
                    <div class="p-2">
                        <div class="form-control">
                            <Input class="w-70" icon="search" placeholder="Search locations" />
                        </div>
                    </div>
                </template>
            </AccordionCard> -->
            <!-- Confidence -->
            <AccordionCard :isFilter="true">
                <template v-slot:title>
                    <span class="f-title flex items-center filter-color ">
                        <ConfidenceScoreSVG class="mx-2" /> Confidence
                    </span>
                    <div class="ml-4">
                        <input type="checkbox" id="checkboxInput"
                        @change="$emit('handleAllCheckboxChange',$event,'confidenceScore')" :checked="isAllConfidanceScoreChecked" />
                        <label  class="mx-1">Select all</label>
                    </div>
                </template>
                <template v-slot:content>
                    <div class="p-2">
                        <div class="form-control">
                            <Input class="w-70" icon="search" v-model="filterData.confidenceScoreTerm"
                                @input="$emit('handleSearchboxChange', $event, 'confidenceScoreTerm')"
                                placeholder="Search confidence score" />
                        </div>
                        <div class="grid grid-cols-2 gap-4 py-2 pl-2">
                            <div c v-for="(cdScore, cIdx) in getConfidenceScore" :key="cdScore.key">
                                <input type="checkbox" v-model="cdScore.checked" class=""
                                    @change="$emit('handleCheckboxChange', $event, cIdx, 'confidenceScore')"
                                    :id="cdScore.key" />
                                <label  class="filter-item-label mx-2">{{ cdScore.key }} ({{ cdScore.count
                                }})</label>
                            </div>
                        </div>

                    </div>
                </template>
            </AccordionCard>
            <AccordionCard :isFilter="true">
                <template v-slot:title>
                    <span class="f-title flex items-center filter-color ">
                        <MatchIdentifiersSVG class="mx-2" /> Matching Identifers
                    </span>
                </template>
                <template v-slot:content>
                    <div class="p-2 ">
                        <div class="cst-input-grid">

                            <div class="form-control mr-1">
                                <CustomInput v-model.number="getMatchIdentifiers[0]" inputType="number" class="w-70"
                                    prefixLabel="MIN" @change="setMin" placeholder="Match Identifiers" />
                            </div>
                            <div class="form-control ml-1">
                                <CustomInput v-model.number="getMatchIdentifiers[1]" inputType="number" class="w-70"
                                    prefixLabel="MAX" @change="setMax" placeholder="Match Identifiers" />
                            </div>
                        </div>
                        <div class="my-10 mx-2">

                            <Slider :min="0" :max="100" :value="getMatchIdentifiers" @setRange="setRange" />
                        </div>
                    </div>
                </template>
            </AccordionCard>
            <AccordionCard :isFilter="true">
                <template v-slot:title>
                    <span class="f-title flex items-center filter-color ">
                        <InternetSVG class="mx-2" /> Internet Queries
                    </span>
                    <div class="ml-4">
                        <input type="checkbox" id="checkboxInput"
                        @change="$emit('handleAllCheckboxChange',$event,'iQueries')" :checked="isAllQueriesChecked" />
                        <label  class="mx-1">Select all</label>
                    </div>
                </template>
                <template v-slot:content>
                    <div class="p-2">
                        <div class="form-control">
                            <Input class="w-70" icon="search" v-model="filterData.internetQueriesTerm"
                                @input="$emit('handleSearchboxChange', $event, 'internetQueriesTerm')"
                                placeholder="Search internet queries" />
                        </div>
                        <div class="grid grid-cols-2 gap-4 py-2 pl-2">
                            <div c v-for="(internetQuery, rIdx) in getInternetQueries" :key="internetQuery.search_query">
                                <input @change="$emit('handleCheckboxChange', $event, rIdx, 'iQueries')" type="checkbox"
                                    :checked="internetQuery.checked" v-model="internetQuery.checked" class="" />
                                <label class="filter-item-label mx-2">{{ internetQuery.maskedQuery }} ({{
                                    internetQuery.count }})</label>
                            </div>
                        </div>
                        <span v-if="!filterData.seeMoreQueries && filterData.iQueries.length > 5"
                            @click="$emit('handleSeeMore', true, 'seeMoreQueries')" class="f-title cursor-pointer pl-2">Load
                            More</span>
                        <span v-if="filterData.seeMoreQueries" @click="$emit('handleSeeMore', false, 'seeMoreQueries')"
                            class="f-title cursor-pointer pl-2">Show Less</span>

                    </div>
                </template>
            </AccordionCard>
            <AccordionCard :isFilter="true">
                <template v-slot:title>
                    <span class="f-title flex items-center filter-color ">
                        <TopicClassificationSVG class="mx-2" /> Topic Classification
                    </span>
                    <div class="ml-4">
                        <input type="checkbox" id="checkboxInput"
                        @change="$emit('handleAllCheckboxChange',$event,'topic_classification')" :checked="isAllTCChecked" />
                        <label  class="mx-1">Select all</label>
                    </div>
                </template>
                <template v-slot:content >
                    <div class="p-2">
                        <div class="form-control">
                            <Input class="w-70" icon="search" v-model="filterData.topicClassifiactionTerm"
                                @input="$emit('handleSearchboxChange', $event, 'topicClassifiactionTerm')"
                                placeholder="Search topic classification" />
                        </div>
                        <div class="grid grid-cols-2 gap-4 py-2 pl-2">
                            <div v-for="(topicClassifiaction, rIdx) in getTopicClassification"
                                :key="topicClassifiaction.key">
                                <input type="checkbox"
                                    @change="$emit('handleCheckboxChange', $event, rIdx, 'topic_classification')"
                                    :checked="topicClassifiaction.checked" v-model="topicClassifiaction.checked" class="" />
                                <label  class="filter-item-label mx-2">{{ topicClassifiaction.key }} ({{
                                    topicClassifiaction.count }})</label>
                            </div>
                        </div>

                    </div>
                </template>
            </AccordionCard>
        </div>
    </div>
</template>
<script>


import AccordionCard from "../../common/accordion";
import PeopleSVG from "../../assets/svg/people.svg";
import Input from "../../../input";
import CustomInput from "../../components/CustomInput/CustomInput.vue";

import CompanySVG from "../../assets/svg/company.svg";
import InternetSVG from "../../assets/svg/internet.svg";
// import LocationSVG from "../../assets/svg/location.svg";
import MatchIdentifiersSVG from "../../assets/svg/match-identifier.svg";
import RiskSVG from "../../assets/svg/risk.svg";
import StatusSVG from "../../assets/svg/status.svg";
import TopicClassificationSVG from "../../assets/svg/tpoic-classification.svg";
import ConfidenceScoreSVG from "../../assets/svg/confidence-score.svg";
import Slider from '../RangeSlider/index.vue'



export default {
    components: {
        AccordionCard,
        CompanySVG,
        InternetSVG,
        // LocationSVG,
        MatchIdentifiersSVG,
        RiskSVG,
        StatusSVG,
        TopicClassificationSVG,
        ConfidenceScoreSVG,
        PeopleSVG,
        Input,
        CustomInput,
        Slider,
    },
    data: () => {
        return {

        }
    },
    props: {
        filterData: {
            type: Object
        },
        handleSearchboxChange: Function,
        handleCheckboxChange: Function,
        handleSeeMore: Function
    },
    mounted() {
        console.log('------------------------------', this.filterData)
    },
    computed: {
        isAllPeopleChecked() {
            // console.log('this.filterData.names', this.filterData.names)
            const checked = this.filterData.names.filter(item => item.checked)
            const count = this.filterData.names.length
            return checked.length === count && count !== 0
        },
        getPeople() {
            const results = this.filterData.names.filter(item => this.caseInsensitiveSearch(item.key, this.filterData.peopleTerm))
            console.log("people",results)
            const max = results.length > 5 ? 5 : results.length
            return this.filterData.seeMorePeople ? results : results.slice(0, max)
        },
        isAllCompanyChecked() {
            // console.log('this.filterData.organisations', this.filterData.organisations)
            const checked = this.filterData.organisations.filter(item => item.checked)
            const count = this.filterData.organisations.length
            return checked.length === count && count !== 0
        },
        getCompanies() {
            const results = this.filterData.organisations.filter(item => this.caseInsensitiveSearch(item.key, this.filterData.companyTerm))
            const max = results.length > 5 ? 5 : results.length
            return this.filterData.seeMoreCompanies ? results : results.slice(0, max)
        },
        isAllStatusChecked() {
            // console.log('this.filterData.status', this.filterData.status)
            const checked = this.filterData.status.filter(item => item.checked)
            const count = this.filterData.status.length
            return checked.length === count && count !== 0
        },
        getStatus() {
            const results = this.filterData.status.filter(item => this.caseInsensitiveSearch(item.key, this.filterData.statusTerm))
            return results
        },
        isAllRisksChecked() {
            // console.log('this.filterData.risks', this.filterData.risks)
            const checked = this.filterData.risks.filter(item => item.checked)
            const count = this.filterData.risks.length
            return checked.length === count && count !== 0
        },
        getRisks() {
            const results = this.filterData.risks.filter(item => this.caseInsensitiveSearch(item.key, this.filterData.riskTerm))
            const max = results.length > 5 ? 5 : results.length
            return this.filterData.seeMoreRisks ? results : results.slice(0, max)
        },
        isAllTCChecked() {
            // console.log('this.filterData.topic_classification', this.filterData.topic_classification)
            const checked = this.filterData.topic_classification.filter(item => item.checked)
            const count = this.filterData.topic_classification.length
            return checked.length === count && count !== 0
        },
        getTopicClassification() {
            const results = this.filterData.topic_classification.filter(item => this.caseInsensitiveSearch(item.key, this.filterData.topicClassifiactionTerm))
            return results
        },
        isAllConfidanceScoreChecked() {
            // console.log('this.filterData.confidenceScore', this.filterData.confidenceScore)
            const checked = this.filterData.confidenceScore.filter(item => item.checked)
            const count = this.filterData.confidenceScore.length
            return checked.length === count && count !== 0
        },
        getConfidenceScore() {
            const results = this.filterData.confidenceScore.filter(item => this.caseInsensitiveSearch(item.key, this.filterData.confidenceScoreTerm))
            return results
        },
        isAllQueriesChecked() {
            // console.log('this.filterData.iQueries', this.filterData.iQueries)
            const checked = this.filterData.iQueries.filter(item => item.checked)
            const count = this.filterData.iQueries.length
            return checked.length === count && count !== 0
        },
        getInternetQueries() {
            const results = this.filterData.iQueries.filter(item => this.caseInsensitiveSearch(item.search_query, this.filterData.internetQueriesTerm))
            const max = results.length > 5 ? 5 : results.length
            return this.filterData.seeMoreQueries ? results : results.slice(0, max)

        },
        getMatchIdentifiers() {
            return this.filterData.matchIdentifiers
        }

    },
    methods: {
        setRange(e) {
            this.$emit('setRange', e)
        },
        setMin(e) {
            const MIN = e.target.value ? parseInt(this.filterNonNumeric(e.target.value)) : this.filters.matchIdentifiers.min
            this.$emit('setMin', MIN)
        },
        setMax(e) {
            const MAX = e.target.value ? parseInt(this.filterNonNumeric(e.target.value)) : this.filters.matchIdentifiers.max
            this.$emit('setMax', MAX)

        },
        filterNonNumeric(val) {
            // Replace non-numeric characters with an empty string
            return val.replace(/[^0-9]/g, "");
        },
        caseInsensitiveSearch(targetString, searchString) {
            const regex = new RegExp(searchString, 'i');
            return regex.test(targetString);
        }
    }
};
</script>                  

<style>
.w-100 {
    width: 100%;
}

.f-title {
    color: var(--light-ray-main-red, #CE1B22);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cst-input-grid {
    display: flex;
    justify-content: space-between;
}

.filters-container {
    max-height: 50vh;
    min-height: 30vh;
    overflow: scroll;
}
</style>