<template>
    <div>
        <h2 class="mb-4">Monitoring</h2>
        <div class="flex items-center w-full ">
            <input v-model="individualData.monitoring" type="checkbox" id="firt_name" class="" />
            <label for="firt_name" class="label mx-2">Save for Ongoing Monitoring</label>
        </div>
    </div>
</template>
<script>
    export default {
    name: "Monitoring",
    components: {
        
    },
    props: {
        individualData: Object,
    },
    data() {
        return {
           monitoring:true
        };
    },
    methods: {
    
    },
    mounted() {
        console.log("=================>",this.individualData)
    },
    computed: {
        getIndividualData(){
            return this.individualData;
        }
    },
};
</script>