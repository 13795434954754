

<template>
  <div>
    <Slider  v-model="value" @change="$emit('setRange',$event)" :min="min" :max="max" />
  </div>
</template>

<script>
  import Slider from '@vueform/slider/dist/slider.vue2.js'

  export default {
    
      components: {
      Slider,
    },
    props:{
      min:{
        type:Number,
        default:0
      },
      max:{
        type:Number,
        default:100
      },
      value:{
        default:[10,40]
      },
      
    },
    
  
  }
</script>

<style src="@vueform/slider/themes/default.css"></style>