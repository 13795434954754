<template>
    <div>
        <button @click="openMenu()" class=" ">
            <Pencil v-if="entityHead" id="isCanvas" />
            <PencilBold v-else />
        </button>
        <offCanvas v-if="showMenu" v-show="showMenu" :isOpen="showMenu" position="right" @click="closeMenu">
            <nav class="h-16 bg-white border-b-2 p-2 flex justify-between static z-50 ">
                <div class="w-100 flex items-center ">
                    <div class="w-10 mr-2">
                        <div class="mx-2 w-9 h-9 rounded-full bg-red-200 flex justify-center items-center">
                            <User />
                        </div>
                    </div>
                    <div v-show="!editName" class="flex justify-between items-center w-full" :title="this.individualData?.entity">
                        <div> {{ this.individualData?.entity?.length > 40 ? `${this.individualData?.entity?.substr(0, 40)}...`
                            :
                            this.individualData?.entity }}</div>
                        <!-- <div @click="openEditName"> <Edit/></div> -->
                    </div>
                    <div v-show="editName" class="border p-2 rounded-md flex justify-between">
                        <input placeholder="Enter" type="text" :value="this.individualData?.name" id="incorporation_date" />
                        <button class="text-red-600">Update</button>
                    </div>
                </div>
                <div class="flex justify-between items-center">
                    <div class="mr-8">
                        <Button type="primary" class="button_outline px-6 py-2" :disabled="this.loadingBtn"
                            @click="updateEntity" text="Save" :loader="this.loadingBtn"></Button>
                        <!-- <button class="button_outline px-6 py-2" @click="updateEntity">Save</button> -->
                    </div>
                    <div class="flex">
                        <div class="mx-1 label-box " :class="confidenceColor">
                            <span>{{ this.individualData?.confidence_score?.score || '0' }}</span>
                        </div>
                        <div :class="this.individualData?.status?.toLowerCase() || 'unknown'"
                            class="mx-2 cursor-pointer w-7 h-7 rounded-full flex justify-center items-center">
                            <MinusIcon
                                v-if="this.individualData?.status?.toLowerCase() == 'unknown' || this.individualData.status == ''"
                                class="cursor-pointer" />
                            <Check v-else-if="this.individualData?.status?.toLowerCase() == 'confirmed'"
                                class="cursor-pointer" />
                            <Question v-else-if="this.individualData?.status?.toLowerCase() == 'potential'"
                                class="cursor-pointer" />
                            <CrossRed v-else-if="this.individualData?.status?.toLowerCase() == 'irrelvant'"
                                class="cursor-pointer" />
                        </div>
                        <div class="">
                            <Threedot />
                        </div>
                        <div class="mx-2 cursor-pointer" @click="closeMenu">
                            <Cross />
                        </div>
                    </div>
                </div>
            </nav>
            <div class="flex">
                <aside class="w-52 h-screen cursor-pointer  text-base-content border-r-2">
                    <ul>
                        <li v-for="item in sidebarItems" :key="item.id" class="pl-3 border-b-2 h-10 hover:bg-red-100"
                            @click="selectPage(item)" :class="{ 'active_card ': isActive(item) }">
                            <div class="flex items-center" :class="{ 'border-r-4 border-red-700': isActive(item) }">
                                <span
                                    class="client-card-info self-stretch w-56  text-sm text-black-600 font-small  capitalize px-2 py-2">
                                    {{ item.name }}
                                </span>
                            </div>
                        </li>
                    </ul>
                </aside>
                <div class="p-5 max-h-screen w-full overflow-scroll">
                    <div v-if="selectedPage === 1">
                        <Attribute :individualData="getIndividualData" :data="attributeData" />
                    </div>
                    <div v-if="selectedPage === 2">
                        <Relationship :individualData="individualData" :mainEntity="this.mainEntity" />
                    </div>
                    <div v-if="selectedPage === 3">
                        <Notes :individualData="getIndividualData" />
                    </div>
                    <div v-if="selectedPage === 4">
                        <AssociatedRisk :individualData="getIndividualData" />
                    </div>
                    <div v-if="selectedPage === 5">
                        <Attachments :individualData="getIndividualData" />
                    </div>
                    <div v-if="selectedPage === 6">
                        <Monitoring :individualData="getIndividualData" />
                    </div>
                    <div v-if="selectedPage === 7">
                        <Details :individualData="getIndividualData" :sourceName="sourceName" />
                    </div>
                </div>
            </div>
        </offCanvas>
    </div>
</template>
<script>
import Button from "../../../components/button";
import PencilBold from "@shared/assets/pencil-simple-line-bold.svg";
import Pencil from '../../assets/svg/pencil.svg';
import offCanvas from '../../common/off-canvas';
// import Edit from '../../assets/svg/edit.svg';
// import Dash from '../../assets/svg/dash.svg';
import Threedot from '../../assets/svg/threedot.svg';
import User from '../../assets/svg/user.svg';
import Cross from '../../assets/svg/cross.svg';
import MinusIcon from "@shared/assets/minus-icon.svg";
import Check from '../../assets/svg/check.svg';
import Question from '../../assets/svg/question.svg';
import Attribute from '../Attributes';
import Relationship from '../Relationships';
import Notes from '../Notes';
import AssociatedRisk from '../Associated-Risk';
import Attachments from '../Attachments';
import Monitoring from '../Monitoring';
import Details from '../Details';
import { updateEntityDetails } from "../../services";
import CrossRed from '../../assets/svg/cross-red.svg';

export default {
    name: "CanvasMenu",
    components: {
        CrossRed,
        Question,
        Check,
        MinusIcon,
        Pencil,
        PencilBold,
        offCanvas,
        // Edit,
        // Dash,
        Threedot,
        Button,
        User,
        Cross,
        Attribute,
        Relationship,
        Notes,
        AssociatedRisk,
        Attachments,
        Monitoring,
        Details,
    },
    props: {
        isMainenity: Boolean,
        mainEntity: Array,
        individualData: Object,
        entityHead: Boolean,
        sourceName: String
    },
    data() {
        return {
            editName: false,
            showMenu: false,
            selectedPage: 2,
            sidebarData: [
                // {
                //     id: 1,
                //     name: "Attributes",
                // },
                {
                    id: 2,
                    name: "Relationships",
                },
                {
                    id: 3,
                    name: "Notes",
                },
                {
                    id: 4,
                    name: "Associated Risks",
                },
                {
                    id: 5,
                    name: "Attachment",
                },
                {
                    id: 6,
                    name: "Monitoring",
                },
                {
                    id: 7,
                    name: "Details",
                }
            ],
            attributeData: {
                companyNumber: '',
                incorporation_date: '',
                dissolution_date: '',
                country: '',
                state: '',
                firstName: '',
                middleName: '',
                lastName: '',
                dob: '',
                gender: '',
                mainEntity: false,
                showGraph: true,
                graphLabel: '',
            },
            loadingBtn: false,
        };
    },
    methods: {
        openEditName() {
            this.editName = !this.editName
        },
        openMenu() { 
            this.showMenu = true 
        },
        closeMenu() { this.showMenu = false },
        selectPage(page) {
            this.selectedPage = page.id
        },
        isActive(item) {
            return this.selectedPage === item.id;
        },
        async updateEntity() {
            this.loadingBtn = true;
            let payload = { ...this.individualData }
            payload = this.individualData
            payload.case_id = this.$route.query.case_id
            await updateEntityDetails(payload, this.individualData?._id)
            this.$toast.success("Sucessfully Updated");
            // this.closeMenu();
            this.loadingBtn = false
        }
    },
    async mounted() {
        // const {isMainenity,mainEntity, individualData,entityHead,sourceName} = this
        // console.log(isMainenity,mainEntity, individualData,entityHead,sourceName)
        //     let payload={
        //         case_id:"PULIN-216",
        //         doc_id:"cfad5f9c-dab9-4272-b4c3-79697633fd16"
        //     }

        //   let adverseData=  await getAdverseDetails(payload);
        //   let payload={
        //         case_id:"PULIN-216",
        //         doc_id:"4eca91f0-2b87-47d1-86ab-9b76756c42ff"
        //     }
        //     await getOffshoreDetails(payload)

        // let payload={
        //     case_id:"PULIN-216",
        //     doc_id:"0354955a-529f-4e1d-b780-902123d4027f"
        // }
        //  await getNewsDetails(payload)


    },
    computed: {
        sidebarItems() {
            if (this.isMainenity || this.sourceName == 'internet') {
                return this.sidebarData.filter(item => item.id != 7)
            }
            else {
                return this.sidebarData
            }
        },
        confidenceColor() {
            return (this.individualData?.confidence_score?.name).toLowerCase();
        },
        getIndividualData() {
            return this.individualData;
        }
    },
};
</script>
<style scoped lang="scss">
.active_card {
    background-color: rgba(145, 0, 0, 0.08);
}

.button_outline {
    border-radius: 8px;
    border: 1px solid var(--light-ray-main-red, #910000);
    background: var(--white, #FFF);
    color: #910000;
    font-size: smaller;
}

.green-box {
    border-radius: 4px;
    background: var(--light-green, #E1F3D3);
    color: var(--complete, #67B52B);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.label-box {
    display: flex;
    width: 25px;
    height: 24px;
    padding: 3px 7px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
}

.high {
    font-size: 12px;
    font-weight: 400;
    color: #67B52B !important;
    background-color: #E1F3D3;
}

.low {
    font-size: 12px;
    font-weight: 400;
    color: #b52b2b !important;
    background-color: #f3d3d3;
}

.medium {
    font-size: 12px;
    font-weight: 400;
    color: #a7b52b !important;
    background-color: #f3f0d3;
}

.unknown {
    border-radius: 30px;
    background: #D7D7D7;
}

.confirmed {
    border-radius: 30px;
    background: #F0F8EA;
}

.potential {
    border-radius: 30px;
    background: rgba(252, 103, 19, 0.11)
}

.irrelvant {
    border-radius: 30px;
    background: #FBEDED;
}</style>