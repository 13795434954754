import { get } from "lodash";
import { DateTime } from "luxon";
import printDataMixin from "@shared/mixins/printDataMixin";
export default {
    components: {

    },
    data(){
        return{
            isPrintLoader:false,
        }
    },
    props: {
        detailsData: Object,
    },
    mounted() {
        console.log("mounted news ", this.detailsData)
    },
    updated() {
        console.log("updated news ", this.detailsData.content)
    },
    mixins: [printDataMixin],
    methods: {
        getObject(object, property, defaultValue) {
            return get(object, property, defaultValue);
        },
        getContent(content) {
            if (!content) {
                return " ";
            }
            let regex = /(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g
            let contentHTML = ''
            contentHTML = content
            return contentHTML.replace(regex, " ")
        },
        getFormattedTime(ts, format) {
            let date = DateTime.fromISO(ts);
            return format ? date.toFormat(format) : date.toFormat("EEE, dd MMM yyyy, hh:mm:ss a");
        },
        async printDocuments(id, fileName, key, noPrint) {
            console.log("print pdf")
            this.isPrintLoader = true;
            let htmlPDF = "";
            htmlPDF = await this.printPage(id, fileName, noPrint)
            return htmlPDF;
        },
        async printPage(id, name, print, noPrint) {
            this.printDone = false;
            this.isPrintLoader = true;
            if (!print) {
                let htmlPDF = await this.printData(id, name, noPrint);
                this.$emit("updatePrintStatus", "");
                this.isPrintLoader = false;
                if (noPrint) {
                    return htmlPDF;
                }
            }
        },
    }
};  
