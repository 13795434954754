<template>
    <div class="my-4 mx-2 " :class="this.expand ? 'grid grid-cols-1 gap-4' : 'grid grid-cols-3 gap-4'">
        <div class="w-auto" v-for="item, indx  in checkedCaseData" :key="i + indx">
            <PepCard :individualData="item"  :mainEntity="mainEntity" :sourceName="sourceName"/>
        </div>
    </div>
</template>
<script>
import PepCard from '../../common/pep-card'
export default {
    components: {
        PepCard,
    },
    props: {
        mainEntity:Array,
        caseData: Array,
        expand: Boolean,
        sourceName:String
    },
    methods: {

    },
    computed: {
        checkedCaseData() {
            return this.caseData
        },
 
    },
    mounted(){
        // console.log("source",this.sourceName)
    }
};
</script>
    
<style></style>