<template>
    <div>
    ,knknknkn
    </div>
</template>
<script>
export default {
  components: {
    
  },
  props: {
   
  },
  mounted(){
    console.log("mounted adverse")
  }
};  

</script>
<style class="scss" scoped>
</style>