<template>
  <div>
    <h2>Attachments </h2>
    <div>
      <FormulateInput ref="file" type="file" v-model="files" class="w-full rounded-xl group cursor-pointer"
        @input="handleFiles" single :add-label="true" :disabled="disabled" />
    </div>
    <!-- <select
      class="form-select block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-red-100 focus:outline-none"
      :class="{ 'border-box-stroke': !error, 'border-red-600': error }" v-model="getValue" style="min-width: 65vw">
      <option :selected="true" :value="null">Select or Add Caption in Image</option>
      <option v-for="option, idx in getOptions" :key="idx" :value="option.name">
        {{ option.name }}
      </option>
    </select> -->
  </div>
</template>
<script>
import "@shared/plugins/formulate";
import {uploadAttachment} from "@shared/light-ray/services";
export default {
  name: "Attachments",
  components: {

  },
  props: {
    individualData:Object,
  },
  data() {
    return {
      files: []
    };
  },
  methods: {
    async handleFiles() {
      let file = this.getOptions()[0].file;
      const formData = new FormData();
      formData.append("file",file);
      let case_id = this.$route.query.case_id;
      let response = await uploadAttachment(formData,case_id)
      this.individualData.screenshot.available = true;
      this.individualData.screenshot["location"] =  response.data.file;
      this.individualData.screenshot["file_name"]=file.name
      this.individualData.screenshot["file_mime_type"]=file.type
      this.individualData.screenshot["size"]=file.size
      
    },
    getPlaceHolder() {
      return 'Select ar Add Caption in Image'
    },
    getOptions() {
      return this.files.files
    }
  },
  mounted() {
  },
  computed: {
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .formulate-input .formulate-input-element {
    min-width: 65vw !important;
  }

  .formulate-input-upload-area {
    height: 10rem;
    min-width: 65vw !important;
  }

  .formulate-input-upload-area-mask {
    height: 10rem;
    min-width: 65vw !important;
  }
}
</style>