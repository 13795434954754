
import { getAdverseDetails, getInternetDetails, getNewsDetails, getOffshoreDetails } from '../../services';
import Offshore from "./offshore-details/offshore.vue";
import Adverse from "./adverse-details/adverse.vue";
import News from "./news-details/news";
import Internet from "./internet-details/internet";

export default {
    name: "Details",
    components: {
        Offshore,
        Adverse,
        News,
        Internet,
    },
    props: {
        sourceName: String,
        individualData: Object,
    },
    data() {
        return {
            detailsData: null

        };
    },
    computed: {

    },
    methods: {
        async handleAdverse() {
            let payload = {
                case_id: this.$route.query.case_id,
                doc_id: this.individualData.main_entities[0].doc_id
            }
            let data = await getAdverseDetails(payload);
            this.detailsData = data.data.details;
        },
        async handleOffshore() {
            let payload = {
                case_id: this.$route.query.case_id,
                doc_id: this.individualData.main_entities[0].doc_id
            }
            let data = await getOffshoreDetails(payload);
            this.detailsData = data.data;
        },
        async handleNews() {
            let payload = {
                case_id: this.$route.query.case_id,
                doc_id: this.individualData.main_entities[0].doc_id
            }
             let data = await getNewsDetails(payload);
             this.detailsData = data.data.details
        },
        async handleInternet() {
            let payload = {
                case_id: this.$route.query.case_id,
                doc_id: this.individualData.main_entities[0].doc_id
            }
            await getInternetDetails(payload)
        },

    },
    async mounted() {
        switch (this.sourceName) {
            case "complyadvantage":
                this.handleAdverse();
                break;
            case "offshoreleaks":
                this.handleOffshore();
                break;
            case "news":
                this.handleNews();
                break;
            case "internet":
                this.handleInternet()
                break;

        }

    },
};
