import { Network } from 'vis-network/standalone';
import vis from "vis-network/dist/vis-network.min.js";


export default {
  mounted() {
    const nodes = new vis.DataSet([
      { id: 1, label: 'Node 1' },
      { id: 2, label: 'Node 2' },
      { id: 3, label: 'Node 3' },
      { id: 4, label: 'Node 4' },
      { id: 5, label: 'Node 5' },
      { id: 6, label: 'Node 6' },
      { id: 7, label: 'Node 7' },
      { id: 8, label: 'Node 8' },
      { id: 9, label: 'Node 9' },
    
    ]);

    const edges = new vis.DataSet([
      { from: 1, to: 2 },
      { from: 3, to: 4 },
      { from: 4, to: 5 },
      { from: 5, to: 6 },
      { from: 6, to: 7 },
      { from: 6, to: 8 },
      { from: 8, to: 9 }
    ]);

    const options = {};
    const container = document.getElementById('network');
    const data = { nodes, edges };
    this.network = new Network(container, data, options);
  },
  beforeDestroy() {
    if (this.network) {
      this.network.destroy();
    }
  },
};