<template>
  <div class="border rounded-md  px-2 py-4" style="height: 5rem;">
    <div class="flex justify-between items-center">
      <div class="mx-1">
        <span class="text-sm font-medium" :title="this.individualData.entity">
          {{ this.individualData?.entity.length > 15 ? `${this.individualData?.entity.substr(0, 15)}...` :
            this.individualData?.entity }}
        </span>
      </div>
      <div class="flex items-center">
        <div class="mx-1 label-box " :class="confidenceColor">
          <span>{{ this.individualData?.confidence_score?.score || '0' }}</span>
        </div>
        <div class="mx-1 label-box  dropdown_parent" :class="this.status">
          <span class="label" @click="this.toogleDropdown">
            <Check v-if="this.status == 'confirmed'" class="cursor-pointer" />
            <Question v-else-if="this.status == 'potential'" class="cursor-pointer" />
            <Cross v-else-if="this.status == 'irrelvant'" class="cursor-pointer" />
            <MinusIcon v-else class="cursor-pointer" />
          </span>
          <ul v-if="showList" class="dropdown_content w-36 text-sm font-small bg-white shadow h-auto py-2 card">
            <li @click="selectDropdown" name="confirmed" 
              class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
              <span name="confirmed" class="" style="color: #67B52B">Confirmed</span>
              <span name="confirmed" class="badge " style="background:#F0F8EA ">
                <Check name="confirmed" />
              </span>
            </li>
            <li @click="selectDropdown" name="potential"
              class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
              <span name="potential" class="" style="color: #FC6713">Potential</span>
              <span name="potential" class="badge " style="background:rgba(252, 103, 19, 0.11) ">
                <Question name="potential" />
              </span>
            </li>
            <li @click="selectDropdown" name="irrelvant"
              class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
              <span name="irrelvant" class="" style="color: #EB3131">Irrelvant</span>
              <span name="irrelvant" class="badge " style="background:#FBEDED">
                <Cross name="irrelvant" />
              </span>
            </li>
            <li @click="selectDropdown" name="unknown" class="p-2 flex items-center justify-between">
              <span name="unknown" class="" style='color:#8D8D8D'>unknown</span>
              <span name="unknown" class="badge" style="background:#D7D7D7">
                <MinusIcon name="unknown" />
              </span>
            </li>
          </ul>
        </div>
        <div class="mx-1">
          <Exclamation  class="cursor-pointer" :fill="this.riskcolor" />
        </div>
        <div class="">
          <Threedot />
        </div>
        <div class="mx-1">
          <CanvasMenu class="cursor-pointer" :individualData="getIndividualData" :mainEntity="mainEntity" :sourceName="sourceName" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Exclamation from "@shared/assets/exclamation-red.svg";
import MinusIcon from "@shared/assets/minus-icon.svg";
import Threedot from '../../assets/svg/threedot.svg';
import CanvasMenu from "../../components/canvas-menu";
import Check from '../../assets/svg/check.svg';
import Question from '../../assets/svg/question.svg';
import Cross from '../../assets/svg/cross-red.svg';
import { updateEntityDetails } from "../../services";


export default {
  components: {
    Exclamation,
    MinusIcon,
    Threedot,
    CanvasMenu,
    Check,
    Question,
    Cross
  },
  props: {
    mainEntity:Array,
    individualData: Object,
    sourceName:String
  },
  data() {
    return {
      showList: false,
      status: 'unknown',
    }
  },
  methods: {
    toogleDropdown() {
      this.showList = !this.showList;
    },
    async selectDropdown(event) {
      this.showList = false;
      let  key  = event.target.innerText
      this.status = key.toLowerCase();
      this.individualData.status = this.status.toUpperCase();
      let payload = {...this.individualData,  case_id : this.$route.query.case_id}
      let data=await updateEntityDetails(payload,this.individualData._id);
      if(data){
       this.$toast.success("Status updated")
      }
    }
  },
  computed: {
    riskcolor(){
     let c = this.individualData?.risk_categories.length>0 ? (this.individualData?.entity_status === 'Red' ? `#EB3131` : `rgb(235, 155, 0)`) : `#8D8D8D`;
     return c  
    },
    getIndividualData() {
      return this.individualData;
    },
    confidenceColor() {
      return (this.individualData?.confidence_score?.name).toLowerCase();
    }
  }, 
  mounted() {
    // console.log(this.individualData)
  },
  created(){
    this.status = (this.individualData.status).toLowerCase() || 'unknown';
  }
};
</script>
<style lang="scss" scoped >
.badge {
  display: flex;
  width: 25px;
  height: 25px;
  // padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0px;
}

.label-box {
  display: flex;
  width: 25px;
  height: 25px;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}

.label {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.unknown{
  border-radius: 30px;
  background: #D7D7D7;
}
.confirmed{
  border-radius: 30px;
  background:#F0F8EA;
}
.potential{
  border-radius: 30px;
  background:rgba(252, 103, 19, 0.11)
}
.irrelvant{
  border-radius: 30px;
  background:#FBEDED; 
}
.high {
  font-size: 12px;
  font-weight: 400;
  color: #67B52B !important;
  background-color: #E1F3D3;
}

.low {
  font-size: 12px;
  font-weight: 400;
  color: #b52b2b !important;
  background-color: #f3d3d3;
}

.medium {
  font-size: 12px;
  font-weight: 400;
  color: #a7b52b !important;
  background-color: #f3f0d3;
}

.dropdown_parent {
  position: relative !important;
}

.badge {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  background-color: white;
}

.dropdown_content {
  position: absolute !important;
  top: 120% !important;
  left: 0%;
  z-index: 99 !important;
  border-radius: 8px;
  background: var(--white, #FFF);
  box-shadow: 2px 2px 11px 0px rgba(68, 82, 90, 0.09);

  li:hover {
    background-color: #D7D7D7;
  }
}</style>