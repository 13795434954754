/*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
import Input from "@/components/input";
import packageCard from "@/components/package-card";
import EntitySidebar from "../../common/sidebar";
import NavbarEntity from "../../common/index";
import NewsCard from "../../common/card-news";
import ButtonGroup from "../../common/button-group";
import Offshore from "../../components/offshore-records"
import AdverseMedia from "../../components/adverse-media"
import FilterCompnent from "../../common/filter-component";
import CompanyCard from "../../components/company-details";
import Loader from "@/components/loader";
import Button from "@/components/button";
import Empty from "@shared/empty";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import modalContent from "@shared/modal-content";
import FormBuilder from "@shared/components/form-builder";
import { checkPermission } from "@shared/utils/functions";
import FilterIcon from "../../assets/svg/filter.svg";
import NoDataIcon from "../../assets/svg/nodatafound.svg";
import GraphIcon from "../../assets/svg/graph.svg";
import RefreshIcon from "../../assets/svg/refresh.svg";
import News from "../../assets/svg/news.svg";
import CanvasMenu from "../../components/canvas-menu";
import GraphCompenent from "../../components/graph"
import LoaderFull from "../../components/loading-screen";
import { mapGetters } from "vuex";
import CoorporateRecord from "../../components/companyDetails";


import { getRunInteligenceDetails, getInteligenceStatus, getinternetDataDetails, getEntityIdentifiers } from "../../services";
import { getCompanyDetails, getGlobalFilters, getInternetQueries, getOwnershiDetails, getjurisdictionList } from "../../pages/search-records/services"
import { isArray } from "lodash";
import Vue from "vue";
export default {
    name: "entity-details",
    components: {
        CoorporateRecord,
        AdverseMedia,
        Input,
        NoDataIcon,
        packageCard,
        Loader,
        Button,
        Empty,
        VueMultiselect,
        modalContent,
        FormBuilder,
        NavbarEntity,
        NewsCard,
        CompanyCard,
        FilterIcon,
        GraphIcon,
        RefreshIcon,
        EntitySidebar,
        CanvasMenu,
        ButtonGroup,
        Offshore,
        FilterCompnent,
        News,
        GraphCompenent,
        LoaderFull,
    },
    title: "Entity Details",
    data() {
        return {
            loading: false,
            globalFilterData: {
                confidenceScore: [
                    { key: "High", count: 0, checked: false },
                    { key: "Low", count: 0, checked: false },
                    { key: "Medium", count: 0, checked: false },
                ],
                status: [
                    { key: "POTENTIAL", checked: false },
                    { key: "UNKNOWN", checked: false },
                    { key: "CONFIRMED", checked: false },
                    { key: "IRRELVANT", checked: false }
                ],
                topic_classification: [
                    { key: "TC One", checked: false, count: 0 },
                    { key: "TC Two", checked: false, count: 0 },
                    { key: "TC Three", checked: false, count: 0 },
                    { key: "TC Four", checked: false, count: 0 },
                    { key: "TC Five", checked: false, count: 0 },
                    { key: "TC Six", checked: false, count: 0 },
                ],
                matchIdentifiers: [0, 0],
                organisations: [], persons: [], names: [], locations: [], categories: [], risks: [], iQueries: []
            },
            entityRecords: [],
            data: [],
            selectedPage: 1,
            clients: [
                {
                    name: "Corporate Records",
                    id: 1,
                    checkAlert: false,
                },
                {
                    name: "Adverse/PEPs/Sanctions",
                    id: 2,
                    checkAlert: false,
                },
                {
                    name: "Offshore Records",
                    id: 3,
                    checkAlert: false,
                },
                {
                    name: "Court Records",
                    id: 4,
                    checkAlert: false,
                },
                {
                    name: "News",
                    id: 5,
                    checkAlert: false,
                },
                {
                    name: "Internet Search",
                    id: 6,
                    checkAlert: false,
                },
                // {
                //     name: "Details",
                //     id: 7,
                //     checkAlert: false,
                //     children: [
                //         {
                //             subname: "Other Names",
                //             id: 81
                //         },
                //         {
                //             subname: "Email",
                //             id: 82
                //         },
                //         {
                //             subname: "Address/Locations",
                //             id: 83
                //         },
                //         {
                //             subname: "Education",
                //             id: 84
                //         },
                //         {
                //             subname: "Employment",
                //             id: 85
                //         },
                //         {
                //             subname: "Phone Number",
                //             id: 86
                //         },
                //         {
                //             subname: "Hobbies",
                //             id: 87
                //         },
                //         {
                //             subname: "Website",
                //             id: 88
                //         },
                //         {
                //             subname: "Domains",
                //             id: 89
                //         },
                //         {
                //             subname: "Events",
                //             id: 90
                //         },
                //         {
                //             subname: "Usernames",
                //             id: 91
                //         },
                //     ]
                // }
            ],
            item: [],
            caseData: [],
            loaders: {
                clients: false,
                package: false,
                case: false,
                modal: false,
                caseExists: false
            },
            selectedClient: null,
            loader: { tools: false, screen: false },
            packages: [],
            package_id: null,
            childComponent: true,
            filter: false,
            graph: false,
            isChecked: true,
            isGrouped: false,
            showMenu: false,
            companyDetails: null,
            companyDetailsData: null,
            jurisdictionData: null,
            touchPagination: { data: [], page: 0, pageSize: 10, },
            memorization: { adverse: [], news: [], offshore: [], court: [], },
            ownershipData: null,
            totalPage: 1,
            sourceName: null,
            loaderData: ['Searching through 1,456,153 Politically Exposed People',
                'Searching through more than 33,000 global news sources',
                'Searching through more than 2,016,524 offshore records',
                'Searching through more than 220,462,742 corporate records',
                'Searching through 1,456,153 Politically Exposed People',
            ]
        };
    },
    computed: {
        ...mapGetters(["getEntitiesData", "getCaseData"]),
        searchedQuery() {
            return this.$route.query
        },
        filterData() {
            return this.globalFilterData
        },
        getLoderData() {
            return this.loaderData;
        },
        filteredClients() {
            return this.clients;
        },
        filteredPackages() {
            return this.packages.filter((pkg) => pkg.package_name.toLowerCase().includes(this.searchKeywordPackages.toLowerCase()));
        },
        selectedPackage() {
            return this.package_id ? this.packages.find((pkg) => pkg.id === this.package_id) : null;
        },
        ifCheckisReference() {
            return this.packageChecks.filter(el => el.category.toLowerCase() === 'reference').length
        },
        filterItem() {
            return this.item;
        },
        filterCaseData() {
            return this.caseData;
        },
        handleAdverse() {
            let temp = [];
            if (this.memorization.adverse.length === 0) {
                if (this.entityRecords.length) {
                    temp = [...temp, ...this.entityRecords]
                }
                this.memorization.adverse = [...temp];
            }
            else {
                temp = [...this.memorization.adverse];
            }
            // temp = this.applyFiltres(temp)
            let x = temp.slice((this.touchPagination.page) * this.touchPagination.pageSize, (this.touchPagination.page + 1) * this.touchPagination.pageSize);
            this.touchPagination.data = [...this.touchPagination.data, ...x];
            return this.touchPagination.data;
        },
        handleNews() {
            let temp = [];
            if (this.memorization.news.length === 0) {
                if (this.entityRecords.length) {
                    temp = [...temp, ...this.entityRecords]
                }
                this.memorization.news = [...temp];
            }
            else {
                temp = [...this.memorization.news];
            }
            // temp = this.applyFiltres(temp)

            let x = temp.slice((this.touchPagination.page) * this.touchPagination.pageSize, (this.touchPagination.page + 1) * this.touchPagination.pageSize);
            this.touchPagination.data = [...this.touchPagination.data, ...x];
            return this.touchPagination.data;
        },
        handleOffshore() {
            let temp = [];
            if (this.memorization.offshore.length === 0) {
                if (this.entityRecords.length) {
                    temp = [...temp, ...this.entityRecords]
                }
                this.memorization.offshore = [...temp];
            }
            else {
                temp = [...this.memorization.offshore];
            }
            // temp = this.applyFiltres(temp)

            let x = temp.slice((this.touchPagination.page) * this.touchPagination.pageSize, (this.touchPagination.page + 1) * this.touchPagination.pageSize);
            this.touchPagination.data = [...this.touchPagination.data, ...x];
            return this.touchPagination.data;
        },
        handleCourt() {
            let temp = [];
            if (this.memorization.court.length === 0) {
                if (this.entityRecords.length) {
                    temp = [...temp, ...this.entityRecords]
                }
                this.memorization.court = [...temp];
            }
            else {
                temp = [...this.memorization.court];
            }
            // temp = this.applyFiltres(temp)
            let x = temp.slice((this.touchPagination.page) * this.touchPagination.pageSize, (this.touchPagination.page + 1) * this.touchPagination.pageSize);
            this.touchPagination.data = [...this.touchPagination.data, ...x];
            return this.touchPagination.data;
        },
        sourceData() {
            return this.sourceName
        }
    },
    async mounted() {
        this.inteligenceDetails();
        if (!this.$route.query.skip) {
            await this.getCompanyData();
            await this.jurisdictionList();
            this.getownershipData();
        }

        this.mainEntity();
    },

    created() {
        this.companyDetailsData = JSON.parse(sessionStorage.getItem(`SelectedData`))
    },

    methods: {
        gotosearch() {
            let tenant_package = sessionStorage.getItem("package_id");
            let client_id = sessionStorage.getItem("client_id");
            this.$router.push({
                name: "search-company-records",
                params: { type: "company-search" }, query: { package_id: tenant_package, client_id }
            });
        },
        applyFiltres(temp) {
            // const appliedFilters = this.getAppliedFilters()
            // Object.keys(appliedFilters).map(key => {
            //     if (appliedFilters[key].length) {
            //         this.touchPagination.page = 0;
            //         this.touchPagination.data = [];
            //         // console.log('appliedFilters[key].length', appliedFilters[key].length, appliedFilters[key], key)
            //         if (key === 'names') {
            //             temp = temp.filter(item => appliedFilters[key].includes(item.entity.trim()))
            //         }
            //         if (key === 'confidenceScore') {
            //             temp = temp.filter(item => appliedFilters[key].includes(item.confidence_score.name.trim()))
            //         }
            //         if (key === 'risks') {
            //             temp = temp.filter(item => item.risk_categories.some(ele => appliedFilters[key].includes(ele)))
            //         }
            //     }
            // })
            return temp
        },
        getAppliedFilters() {
            const filters = {}
            Object.keys(this.globalFilterData).map(key => {
                // console.log('key', key,this.globalFilterData[key],isArray(this.globalFilterData[key]))
                if (isArray(this.globalFilterData[key])) {
                    if (key == 'matchIdentifiers') {
                        filters.matching_identifiers_min = this.globalFilterData.matchIdentifiers[0]
                        filters.matching_identifiers_max = this.globalFilterData.matchIdentifiers[1]
                    } else {
                        this.globalFilterData[key].map(item => {
                            if (item.checked) {
                                let value = item.key.trim()
                                if (key == 'status') {
                                    value = value == 'UNKNOWN' ? '' : value.toUpperCase();
                                }
                                if (!filters[key]) {
                                    filters[key] = []
                                }
                                filters[key].push(value)
                            }

                        })
                    }
                }
            })
            // console.log('this.globalFilterData', filters)
            return filters
        },
        handleScroll(event) {
            const { scrollTop, scrollHeight, clientHeight } = event.target;
            if (scrollHeight - scrollTop <= clientHeight) {
                this.touchPagination.page++;
            }
        },
        getSelectedEntityIds() {
            let mainentity_id = this.caseData?.filter(d => d.checked)
            return mainentity_id = mainentity_id?.map(d => d._id)
        },
        updateSelectedPage(page) {
            this.loading = true;
            this.touchPagination.page = 0;
            this.selectedPage = page;
            this.entityRecords = [];
            this.touchPagination.data = [];
            let mainentity_id = this.getSelectedEntityIds()
            let source_name = "complyadvantage"
            this.sourceName = source_name
            if (page != 6) {
                switch (page) {
                    case 2:
                        source_name = "complyadvantage";
                        this.sourceName = source_name
                        this.touchPagination.pageSize = 25;
                        break;
                    case 3:
                        source_name = "offshoreleaks"
                        this.sourceName = source_name
                        this.touchPagination.pageSize = 25;
                        break;
                    case 4:
                        source_name = "judyrecords"
                        this.sourceName = source_name
                        break;
                    case 5:
                        source_name = "news"
                        this.sourceName = source_name
                        break;
                }
                const payload = { entity_ids: mainentity_id, source_name }
                const appliedFilters = this.getAppliedFilters()
                Object.keys(appliedFilters).forEach(key => {
                    if (appliedFilters[key].length) {
                        if (key == 'risks') {
                            payload.risk_categories = appliedFilters.risks
                        } else if (key == 'confidenceScore') {
                            payload.confidence_core = appliedFilters.confidenceScore
                        } else if (key == 'iQueries') {
                            payload.internet_queries = appliedFilters.iQueries
                        } else if (key == 'topicClassification') {
                            payload.topic_classification = appliedFilters.topicClassification
                        } else if (key == 'names') {
                            payload.peoples = appliedFilters.names
                        } else if (key == 'organisations') {
                            payload.companies = appliedFilters.organisations
                        } else {
                            payload[key] = appliedFilters[key]
                        }
                    } else if (!isArray(appliedFilters[key])) {
                        payload[key] = appliedFilters[key]
                    }

                })

                // memorization: { adverse: [], news: [], offshore: [], court: [], },
                this.memorization = { adverse: [], news: [], offshore: [], court: [], };
                if (mainentity_id.length) {
                    this.entityDataSourceBased(payload);
                } else {
                    this.loading = false;
                }
            } else {
                this.sourceName = "internet"
                this.getInternetData(1);
            }
        },
        async getCompanyData() {
            const payload = {
                card_type: "company",
                code: this.$route.query?.code,
                doc_id: this.$route.query?.doc_id,
                number: this.$route.query?.company,
                query_id: this.$route.query?.query_id,
            }
            const companyDetails = await getCompanyDetails(payload)
            this.companyDetails = companyDetails.data.data;
        },
        async jurisdictionList() {
            const result = await getjurisdictionList();
            this.jurisdictionData = result.data.data;
        },
        async entityDataSourceBased(source) {
            let payload = {
                case_id: this.$route.query.case_id,
                ...source,
            }
            let data = await getEntityIdentifiers(payload);
            this.loading = false;
            this.entityRecords = data.data.data;
            if (this.sourceName == "complyadvantage") {
                this.clients[1].checkAlert = this.entityRecords.some(item => (item.risk_categories.length))
            }
            else if (this.sourceName == "offshoreleaks") {
                this.clients[2].checkAlert = this.entityRecords.some(item => (item.risk_categories.length))
            }
            else if (this.sourceName == "judyrecords") {
                this.clients[3].checkAlert = this.entityRecords.some(item => (item.risk_categories.length))
            }
            else if (this.sourceName == "news") {
                this.clients[4].checkAlert = this.entityRecords.some(item => (item.risk_categories.length))
            }
        },
        async mainEntity() {
            let payload = {
                case_id: this.$route.query.case_id,
                main_entity: true,
            }
            let data = await getEntityIdentifiers(payload);
            let temp = []
            data.data.data.forEach(item => {
                if (item.title == 'New Person') {
                    temp.push({ ...item, name: `${item.entity}`, categories_type: "officers", checked: true })
                }
                else if (item.title == 'New Company') {
                    temp.push({ ...item, name: `${item.entity}`, categories_type: "ownership", checked: true })
                }
            })
            this.caseData = [...temp]
        },
        async inteligenceDetails() {
            this.loader.screen = true;
            let payload = {
                case_id: this.$route.query.case_id,
            }
            await getRunInteligenceDetails(payload);
            let flag = 1;
            while (flag) {
                let data = await getInteligenceStatus(payload);
                if (data.data.data.completed === true) {
                    this.getGlobalFiltersData()
                    this.loader.screen = false;
                    flag = -1;
                    break;
                }
                await new Promise(resolve => setTimeout(resolve, 5000));
            }
        },
        checkPermission,
        handleItemChecked(item, index) {
            // Update the state or perform any necessary action based on the checkbox chang 
            let check = this.caseData.every((item) => item.checked);
            check ? this.isChecked = true : this.isChecked = false;
            this.caseData[index].checked = item.checked
            this.updateSelectedPage(this.selectedPage)
            this.getGlobalFiltersData()

        },
        handleSelectALL() {
            this.caseData.forEach(ele => {
                ele.checked = this.isChecked
            });
            if (!this.isChecked) {
                this.memorization = { adverse: [], news: [], offshore: [], court: [], }
                this.entityRecords = []
            }
            this.updateSelectedPage(this.selectedPage);
        },
        async getInternetData(no) {
            const appliedFilters = this.getAppliedFilters()
            let payload = { case_id: this.$route.query.case_id, query_ids: appliedFilters.iQueries }
            let entity_ids = this.getSelectedEntityIds();
            payload.entity_ids = entity_ids
            payload.limit = this.touchPagination.pageSize
            payload.current_page = no;
            const internetData = await getinternetDataDetails(payload)
            this.item = [...this.item, ...internetData.data.data];
            this.totalPage = internetData.data?.pagination_info?.total_pages
            this.loading = false;
        },
        async getownershipData() {
            const newPayload = { comp_id: this.searchedQuery.company };
            let owner = await getOwnershiDetails(newPayload);
            this.ownershipData = owner.data.entity
        },
        async getGlobalFiltersData() {
            try {
                const selectedEntities = this.getSelectedEntityIds()
                const payloads = ["organisation", "person", "name", "location", "category", "risks"].map(item => {
                    return {
                        "case_id": this.$route.query.case_id,
                        "keyword_type": item,
                        "search_string": "",
                        "entity_ids": selectedEntities,
                        "page": 1, "limit": 100
                    }
                })

                const qPayload = {
                    "case_id": this.$route.query.case_id,
                    "run_status": "run",
                    "done_status": "all",
                    "visited_status": "all",
                    "entity_ids": selectedEntities
                }
                const promises = payloads.map(payload => getGlobalFilters(payload));
                promises.push(getInternetQueries(qPayload))
                const [organisations, persons, names, locations, categories, risks, iQueries] = await Promise.all(promises)
                const queries = iQueries?.queries?.map(query => {
                    return {
                        ...query,
                        key: query._id,
                        maskedQuery: query.maskedQuery,
                    }
                })
                this.globalFilterData = {
                    ...this.globalFilterData,
                    organisations, persons, names, locations, categories, risks, iQueries: queries
                }
                // console.log('this.globalFilterData', this.globalFilterData)
            } catch (err) {
                console.log(err)
            }
        },
        toggleMenu() { this.showMenu = !this.showMenu },
        toggleFilter() { this.filter = !this.filter },
        toggleGraph() { this.graph = !this.graph, this.childComponent = true },
        expandGraph() { this.filter = false, this.childComponent = !this.childComponent },
        onEditNewsRecord() { console.log('onEditNewsRecord') },
        onInfoNewsRecord() { console.log('onInfoNewsRecord') },
        onEditCourtRecord() { console.log('onEditCourtRecord') },
        onInfoCourtRecord() { console.log('onInfoCourtRecord') },
        onGroup() { this.isGrouped = true },
        onUnGroup() { this.isGrouped = false },
        handleSearchboxChange(value, field) {
            this.globalFilterData[field] = value
        },
        handleAllCheckboxChange(event, field) {
            this.globalFilterData[field].map(item => {
                item.checked = event.target.checked ? true : false;
                return item;
            })
            this.globalFilterData = JSON.parse(JSON.stringify(this.globalFilterData))
            this.updateSelectedPage(this.selectedPage)

        },
        resetFilter() {
            Object.keys(this.globalFilterData).map(key => {
                if (key === 'matchIdentifiers') {
                    this.globalFilterData[key] = [0, 0]
                } else {
                    this.globalFilterData[key].map(item => {
                        item.checked = false
                        return item
                    })
                }
            })
            this.updateSelectedPage(this.selectedPage)
        },
        handleSeeMore(value, field) {
            // this.globalFilterData[field] = value
            Vue.set(this.globalFilterData, field, value)
            // this.globalFilterData = JSON.parse(JSON.stringify(this.globalFilterData))
        },
        setRange(e) {
            // this.$data.filters.matchIdentifiers.value = e
            Vue.set(this.globalFilterData, 'matchIdentifiers', e)
            this.updateSelectedPage(this.selectedPage)

        },
        setMin(e) {
            this.globalFilterData.matchIdentifiers[0] = e
            this.updateSelectedPage(this.selectedPage)

        },
        setMax(e) {
            this.globalFilterData.matchIdentifiers[1] = e
            this.updateSelectedPage(this.selectedPage)

        },
        handleCheckboxChange(event, index, field) {
            // console.log('field', field, field === 'topic_classification')
            const { checked } = event.target
            // this.globalFilterData[field][index].checked = checked
            Vue.set(this.globalFilterData[field][index], 'checked', checked)
            this.updateSelectedPage(this.selectedPage)
        }
    },
    watch: {
        "touchPagination.page": function () {
            if (this.touchPagination.page <= this.totalPage)
                this.getInternetData(this.touchPagination.page);
        }
    },
};
