/*  */
import { get } from "lodash";
import printDataMixin from "@shared/mixins/printDataMixin";
export default {
  components: {

  },
  props: {
    adverseCard: Object,
    connection:Array,
  },
  data() {
    return {
      expandedDetails: {
        Associates: true,
      },
    }
  },
  mixins: [printDataMixin],
  methods: {
    get,
    handleExpandAll() {
      this.expandedDetails.Associates = true
      this.expandAll = false;
    },
    handleCollapseAll() {
      this.expandedDetails.Associates = false
      this.expandAll = true
    },
    getRole(value) {
        return value
            ?.split(" of")
            .filter((el) => el)
            .join("of");
    },
    async printDocuments(id, fileName, key, noPrint) {
        this.isPrintLoader = true;
        let htmlPDF = "";
        htmlPDF = await this.printPage(id, fileName, noPrint)
        return htmlPDF;
    },
    async printPage(id, name, print, noPrint) {
        this.printDone = false;
        this.isPrintLoader = true;
        if (!print) {
            let htmlPDF = await this.printData(id, name, noPrint);
            this.$emit("updatePrintStatus", "");
            this.isPrintLoader = false;
            if (noPrint) {
                return htmlPDF;
            }
        }
    },
  },
  computed:{
      getConnections(){
        let  conection_data = {"officer":[],"intermediary":[],"entity":[],"other":[] }
        if(this.connection){
            this.connection.forEach(item => {
                if( Object.prototype.hasOwnProperty.call(conection_data, item.doc.data_type)){
                    conection_data[item.doc.data_type].push(item);
                }
                else{
                    conection_data['other'].push(item)
                }
            })
        }
        return conection_data;
      }
  },
  mounted() {
   
  },
  updated(){
   
  }
};